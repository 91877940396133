import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/de'
import Heading from '../components/Heading'

const lang = 'de'

export default class ContactPage extends React.Component {
  render() {
    const { content } = this.props.data.imprint.translations.find(
      (t) => t.language === lang
    )
    const { location } = this.props

    return (
      <Layout location={location}>
        <div className="w-100 pa4 tc flex flex-column justify-center items-center">
          <Heading>Impressum.</Heading>
          <div
            className="measure-wide mt4 lh-copy tl"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    imprint: directusPage(title: { eq: "Imprint" }) {
      id
      title
      translations {
        content
        language
      }
    }
  }
`
